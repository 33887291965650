import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent } from '@shared/lib/numbers';
import { ReactComponent as LowRiskIcon } from '@icons/wolfkit-light/emoji-smile-light.svg';
import { ReactComponent as AverageRiskIcon } from '@icons/wolfkit-light/emoji-meh-icon.svg';
import { ReactComponent as HighRiskIcon } from '@icons/wolfkit-light/emoji-frown-light.svg';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import defineDrawdownRisk from '../../../lib';
const DrawdownTitleContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
}));
const DrawdownInner = styled.div(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
}));
const DrawdownEstimation = styled.div();
const SectionTitleText = styled.span(() => ({
    flex: '0 0 auto',
    fontSize: 16,
    fontWeight: 500,
}));
const SectionTitleFiller = styled.span(() => ({
    display: 'flex',
    flex: '1 1 auto',
    height: '1px',
    margin: '0px 2px 4px',
    alignSelf: 'end',
    backgroundImage: 'linear-gradient(to right, #7E868C 10%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 2px',
    backgroundRepeat: 'repeat-x',
}));
const SectionTitleValue = styled.span(() => ({
    flex: '0 0 auto',
    alignSelf: 'start',
    fontWeight: 600,
    fontSize: 20,
}));
const defineRiskTextColor = (theme, risk) => {
    switch (risk) {
        case 'low':
            return theme.palette.success.main;
        case 'average':
            return theme.palette.warning.main;
        case 'high':
            return theme.palette.error.main;
        default:
            return '';
    }
};
const DrawdownRiskContainer = styled.span(props => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs / 2,
    fontSize: props.theme.baseSize * 2,
    color: defineRiskTextColor(props.theme, props.risk),
}));
const DrawdownRisk = ({ value = undefined, isLoading = false, }) => {
    const { t } = useTranslation();
    const { icon, text, risk } = useMemo(() => {
        const res = defineDrawdownRisk(value);
        switch (res) {
            case 'low':
                return {
                    icon: _jsx(LowRiskIcon, {}),
                    text: t('portfolio_overview.risks.low', { ns: 'common' }),
                    risk: 'low',
                };
            case 'average':
                return {
                    icon: _jsx(AverageRiskIcon, {}),
                    text: t('portfolio_overview.risks.average', { ns: 'common' }),
                    risk: 'average',
                };
            case 'high':
                return {
                    icon: _jsx(HighRiskIcon, {}),
                    text: t('portfolio_overview.risks.high', { ns: 'common' }),
                    risk: 'high',
                };
            default:
                return { icon: null, text: '' };
        }
    }, [value]);
    return (_jsx(DrawdownRiskContainer, { risk: risk, children: _jsxs(WithSkeleton, { isLoading: isLoading, width: 40, height: 18, children: [icon, text] }) }));
};
const DrawdownTitle = ({ title = null, value = 0, isLoading = false, }) => (_jsxs(DrawdownTitleContainer, { children: [_jsxs(DrawdownInner, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 90, height: 18, children: _jsx(SectionTitleText, { children: title }) }), !isLoading && _jsx(SectionTitleFiller, {}), _jsx(WithSkeleton, { isLoading: isLoading, width: 60, height: 24, children: _jsx(SectionTitleValue, { children: formatPercent(value, {
                            enablePlusSign: !!value,
                            maximumFractionDigits: value === 0 ? 0 : 2,
                        }) }) })] }), (value !== 0 || isLoading) &&
            (_jsx(DrawdownEstimation, { children: _jsx(DrawdownRisk, { value: value, isLoading: isLoading }) }))] }));
export default DrawdownTitle;
